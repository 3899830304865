<template>
  <div class="section-profile">
    <b-modal
      size="lg"
      id="collaborators"
      header-bg-variant="info"
      header-text-variant="light"
      title="Usuários subordinados"
      ref="collaborators"
      hide-footer
      scrollable
    >
      <div
        class='modal_font_size'
           v-if='user && user.subordinateUsers && user.subordinateUsers.collaborators && user.subordinateUsers.collaborators.length > 0'
      >
        <ol class='ml-3'>
          <li
            class='mb-2'
            v-for="collaborate in user.subordinateUsers.collaborators"
            :key='collaborate._id'
          >
            <strong>E-mail:</strong> {{collaborate.username}}
            <ul class='ml-3' style='list-style-type: disc;'>
              <li
                v-if='collaborate.plan && collaborate.plan._id'
              >
                <span><strong>Data de criação do plano:</strong> {{formatDate(collaborate.plan.createdAt)}}</span><br/>
              </li>
              <li
                v-if='collaborate.plan && collaborate.plan._id'
              >
                <span><strong>Data de vencimento do plano:</strong> {{formatDateDueDate(collaborate.plan.dueDate)}}</span>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </b-modal>
    <h3>Dados pessoais</h3>
    <div v-if='user.profile' class="data align-items-start w-100">
      <div class="d-flex flex-column w-50">
        <span>
          <strong>Nome: </strong>
          {{user.profile && user.profile.name ? user.profile.name : '-'}}
        </span>

        <span v-if="user.username">
          <strong>Email: </strong>
          {{user.username}}
        </span>

        <span>
          <strong>Telefone: </strong>
          {{user.profile && user.profile.phone ? user.profile.phone : '-'}}
        </span>

        <span>
          <strong>Telefone comercial: </strong>
          {{user.profile && user.profile.company && user.profile.company.phone ? user.profile.company.phone: '-'}}
        </span>

        <span>
          <strong>Data do cadastro: </strong>
          {{user.createdAt ? formatDate(user.createdAt) : '-'}}
        </span>

        <span>
          <strong>CPF: </strong>
          {{
            user.profile && user.profile.cpf && user.profile.cpf.length >= 11 ?
              user.profile.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
              :  '-'
          }}
        </span>

        <span>
          <strong>CNPJ: </strong>
          {{
            user.profile && user.profile.company && user.profile.company.cnpj && user.profile.company.cnpj.length >= 14 ?
              user.profile.company.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"):
              '-'
          }}
        </span>

        <span>
          <strong>Não solicitar autenticação em dois fatores até qual data?: </strong>
          {{user.twoFactorAuthenticationReleaseDeadline ? formatDate(user.twoFactorAuthenticationReleaseDeadline) : '-'}}
        </span>

        <span>
          <strong>Quando a política de privacidade foi aceita?: </strong>
         {{user.dataAcceptedPrivacyPolicyConfirmation ? formatDate(user.dataAcceptedPrivacyPolicyConfirmation) : '-'}}
        </span>

        <span>
          <strong>Política de privacidade aceita?: </strong>
          {{user.privacyPolicyConfirmation ? 'Sim' : 'Não'}}
        </span>

        <span>
          <strong>Url do contrato de política de privacidade aceito: </strong>
          <a v-if='user.privacyPolicyUrl' :href='user.privacyPolicyUrl'>
            Ver {{user.privacyPolicyName ? user.privacyPolicyName : 'Política de privacidade'  }}
          </a>
          <span v-else>-</span>
        </span>

        <span>
          <strong>Quando o contrato de política de privacidade foi anexado ao sistema: </strong>
           {{user.privacyPolicyDate ? formatDate(user.privacyPolicyDate) : '-'}}
        </span>
      </div>

      <div class="d-flex flex-column w-50 ml-5">
        <span>
          <strong>A conta foi ativada?: </strong>
          {{user.confirmed ? 'Sim' : 'Não'}}
        </span>

        <span>
          <strong>Quando a conta foi ativada?: </strong>
         {{user.confirmationDate ? formatDate(user.confirmationDate) : '-'}}
        </span>

        <span>
          <strong>Url do contrato de Temos de uso aceito: </strong>
          <a v-if='user.termsOfUseUrl' :href='user.termsOfUseUrl'>
            Ver {{user.termsOfUseName ? user.termsOfUseName : 'Termos de uso'  }}
          </a>
          <span v-else>-</span>
        </span>

        <span>
          <strong>Quando o contrato de Temos de uso foi anexado ao sistema: </strong>
           {{user.termsOfUseDate ? formatDate(user.termsOfUseDate) : '-'}}
        </span>

        <span>
          <strong>Termos de uso aceito?: </strong>
          {{user.termsConfirmation ? 'Sim' : 'Não'}}
        </span>

        <span>
          <strong>Quando os termos de uso foram aceitos?: </strong>
         {{user.dataAcceptedTermsConfirmation ? formatDate(user.dataAcceptedTermsConfirmation) : '-'}}
        </span>

        <span>
          <strong>Dados de perfil preenchidos e atualizados?: </strong>
          {{checkProfileIsFilled(user)}}
        </span>

        <span>
          <strong>Tempo extra de acesso: </strong>
          {{user.endDateExtraAccessTime ? formatQuantityDaysExtraAccess(user.endDateExtraAccessTime) : 0}} <strong>dia(s)</strong>
        </span>

        <span>
          <strong>Quantidade de usuários subordinados: </strong>
          <span v-if='user.subordinateUsers && user.subordinateUsers.collaborators && user.subordinateUsers.collaborators.length > 0'>
            {{user.subordinateUsers.collaborators.length}} <b-link class='cursor-pointer' @click="$refs.collaborators.show()"><strong>Ver subordinados</strong></b-link>
          </span>
          <span v-else>
            -
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import moment from 'moment'
import {profileIsFilled} from '../../utils/checkProfileIsFilled'

export default {
  name: 'SectionProfile',
  props: {
    user: { type: Object, required: true }
  },
  computed:{
    userCurrent() {
      return this.$store.getters['user/current']
    },
  },
  methods: {
    formatDateDueDate(date) {
      const dueDate = new Date(date)
      return moment(new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate(), 23, 59, 59)).format('DD/MM/YYYY HH:mm:ss')
    },
    checkProfileIsFilled(user) {
      return profileIsFilled(user) ? 'Sim': 'Não'
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    formatQuantityDaysExtraAccess(date) {
      if(moment(new Date(date)).isAfter(this.userCurrent.currentDate)) {
        let quantityDaysExtraAccess = parseInt(moment.duration(moment(new Date(date)).diff(moment(new Date(this.userCurrent.currentDate)))).asDays())
        if (this.user.plan && this.user.plan.status === 'paid') {
          if (moment(new Date(date)).isAfter(new Date(this.user.plan.dueDate))) {
            quantityDaysExtraAccess = Math.ceil(moment.duration(moment(new Date(date)).diff(moment(new Date(this.user.plan.dueDate)))).asDays())
          } else if (moment(new Date(date)).isAfter(new Date(this.userCurrent.currentDate))) {
            quantityDaysExtraAccess = Math.ceil(moment.duration(moment(new Date(date)).diff(moment(new Date(this.userCurrent.currentDate)))).asDays())
          }
        }
        return quantityDaysExtraAccess > 0 ? quantityDaysExtraAccess : 1
      }
      return 0
    },
  }
}
</script>

<style lang="scss" scoped>

.section-profile {
  display: flex;
  flex-direction: column;
  gap: 4px;

  h3 {
    font-weight: bold;
  }

  .data {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
}
</style>
